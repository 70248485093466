.blog-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  /* padding-top: 70px; */
  flex-direction: column;
  background-color: #ffffff;
}
.blog-navbar {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 1000;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}
.blog-header {
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.blog-navbar1 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.blog-middle {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 1;
  border-color: transparent;
  justify-content: center;
}
.blog-left {
  display: flex;
  grid-gap: 45px;
  position: relative;
  align-items: flex-start;
}
.blog-text {
  text-decoration: none;
}
.blog-text01 {
  text-decoration: none;
}
.blog-text02 {
  text-decoration: none;
}
.blog-container01 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
}
.blog-image {
  width: var(--dl-size-size-small);
  object-fit: cover;
}
.blog-logo-center {
  align-self: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.blog-right {
  display: flex;
  grid-gap: 45px;
  position: relative;
  align-items: flex-start;
}
.blog-text05 {
  text-decoration: none;
}
.blog-text07 {
  text-decoration: none;
}
.blog-burger-menu {
  display: none;
}
.blog-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  display: none;
}
.blog-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  padding: 32px;
  z-index: 100;
  position: absolute;
  transform: translateX(100%);
  transition: 0.3s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.blog-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.blog-container02 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.blog-logo-center1 {
  color: var(--dl-color-gray-black);
  height: auto;
  font-size: 24px;
  font-family: Jost;
  font-weight: 500;
  line-height: 20px;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
  letter-spacing: 0.4em;
  text-decoration: none;
}
.blog-close-mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.blog-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.blog-middle1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
}
.blog-text08 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.blog-text09 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.blog-text10 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.blog-text11 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.blog-text12 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.blog-text13 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.blog-container03 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.blog-link {
  display: contents;
}
.blog-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.blog-link1 {
  display: contents;
}
.blog-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  text-decoration: none;
}
.blog-link2 {
  display: contents;
}
.blog-image01 {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.blog-blog {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.blog-navlink {
  display: contents;
}
.blog-container04 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
}
.blog-blog-post-card {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 4px 4px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.blog-blog-post-card:hover {
  transform: scale(1.02);
}
.blog-image02 {
  width: 350px;
  height: 402px;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: 0px;
}
.blog-container05 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.blog-container06 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.blog-text14 {
  color: var(--dl-color-gray-500);
  font-weight: 600;
  text-transform: uppercase;
}
.blog-text15 {
  color: var(--dl-color-gray-500);
  font-weight: 300;
}
.blog-text16 {
  margin-bottom: var(--dl-space-space-twounits);
}
.blog-text17 {
  color: var(--dl-color-gray-500);
  font-size: 1.25rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.blog-container07 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.blog-profile {
  display: flex;
  align-items: center;
}
.blog-image03 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.blog-text18 {
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
}
.blog-text19 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-weight: 500;
}
.blog-container08 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.blog-navlink1 {
  display: contents;
}
.blog-blog-post-card1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 4px 4px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
}
.blog-blog-post-card1:hover {
  transform: scale(1.02);
}
.blog-image04 {
  width: 350px;
  height: 412px;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: 0px;
}
.blog-container09 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.blog-container10 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.blog-text20 {
  color: var(--dl-color-gray-500);
  font-weight: 600;
  text-transform: uppercase;
}
.blog-text21 {
  color: var(--dl-color-gray-500);
  font-weight: 300;
}
.blog-text22 {
  margin-bottom: var(--dl-space-space-twounits);
}
.blog-text23 {
  color: var(--dl-color-gray-500);
  font-size: 1.25rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.blog-container11 {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.blog-profile1 {
  display: flex;
  align-items: center;
}
.blog-image05 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.blog-text24 {
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
}
.blog-text25 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-weight: 500;
}
.blog-navlink2 {
  display: contents;
}
.blog-container12 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
}
.blog-blog-post-card2 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 4px 4px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.blog-blog-post-card2:hover {
  transform: scale(1.02);
}
.blog-image06 {
  width: 350px;
  height: 412px;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: 0px;
}
.blog-container13 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.blog-container14 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.blog-text26 {
  color: var(--dl-color-gray-500);
  font-weight: 600;
  text-transform: uppercase;
}
.blog-text27 {
  color: var(--dl-color-gray-500);
  font-weight: 300;
}
.blog-text28 {
  margin-bottom: var(--dl-space-space-twounits);
}
.blog-text29 {
  color: var(--dl-color-gray-500);
  font-size: 1.25rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.blog-container15 {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.blog-profile2 {
  display: flex;
  align-items: center;
}
.blog-image07 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.blog-text30 {
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
}
.blog-text31 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-weight: 500;
}
.blog-navlink3 {
  display: contents;
}
.blog-container16 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
}
.blog-blog-post-card3 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 4px 4px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.blog-blog-post-card3:hover {
  transform: scale(1.02);
}
.blog-image08 {
  width: 350px;
  height: 412px;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: 0px;
}
.blog-container17 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.blog-container18 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.blog-text32 {
  color: var(--dl-color-gray-500);
  font-weight: 600;
  text-transform: uppercase;
}
.blog-text33 {
  color: var(--dl-color-gray-500);
  font-weight: 300;
}
.blog-text34 {
  margin-bottom: var(--dl-space-space-twounits);
}
.blog-text35 {
  color: var(--dl-color-gray-500);
  font-size: 1.25rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.blog-container19 {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.blog-profile3 {
  display: flex;
  align-items: center;
}
.blog-image09 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.blog-text36 {
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
}
.blog-text37 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-weight: 500;
}
.blog-navlink4 {
  display: contents;
}
.blog-blog-post-card4 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 4px 4px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
}
.blog-blog-post-card4:hover {
  transform: scale(1.02);
}
.blog-image10 {
  width: 350px;
  height: 400px;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: 0px;
}
.blog-container20 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.blog-container21 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.blog-text38 {
  color: var(--dl-color-gray-500);
  font-weight: 600;
  text-transform: uppercase;
}
.blog-text39 {
  color: var(--dl-color-gray-500);
  font-weight: 300;
}
.blog-text40 {
  margin-bottom: var(--dl-space-space-twounits);
}
.blog-text41 {
  color: var(--dl-color-gray-500);
  font-size: 1.25rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.blog-container22 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.blog-profile4 {
  display: flex;
  align-items: center;
}
.blog-image11 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.blog-text42 {
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
}
.blog-text43 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-weight: 500;
}
.blog-footer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-950);
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  background-color: #ffffff;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.blog-max-width {
  width: 100%;
  background-color: #ffffff;
}
.blog-footer1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: space-between;
}
.blog-container23 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  margin-left: var(--dl-space-space-sevenunits);
  flex-direction: column;
}
.blog-image12 {
  width: var(--dl-size-size-medium);
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unit);
}
.blog-text44 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.blog-container24 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.blog-link3 {
  display: contents;
}
.blog-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.blog-link4 {
  display: contents;
}
.blog-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.blog-container25 {
  flex: 0 0 auto;
  width: var(--dl-size-size-xsmall);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.blog-link5 {
  display: contents;
}
.blog-image13 {
  width: var(--dl-size-size-xsmall);
  object-fit: cover;
  text-decoration: none;
}
.blog-text45 {
  color: var(--dl-color-gray-black50);
  margin-top: var(--dl-space-space-oneandhalfunits);
}
.blog-text46 {
  color: var(--dl-color-gray-black50);
  margin-top: var(--dl-space-space-twounits);
}
.blog-text47 {
  color: var(--dl-color-gray-black50);
  margin-top: 12px;
}
.blog-text48 {
  color: var(--dl-color-gray-black50);
  margin-top: 12px;
}
.blog-links-container {
  display: flex;
  grid-gap: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.blog-container26 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  justify-content: flex-start;
}
.blog-text51 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.blog-navlink5 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.blog-navlink6 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.blog-navlink7 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.blog-navlink8 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
@media (max-width: 991px) {
  .blog-left {
    display: none;
  }
  .blog-right {
    display: none;
  }
  .blog-burger-menu {
    display: flex;
  }
  .blog-icon {
    width: 24px;
    height: 24px;
    display: flex;
  }
  .blog-mobile-menu {
    width: 967px;
  }
  .blog-logo-center1 {
    color: var(--dl-color-gray-black);
    font-size: 24px;
    font-family: Jost;
    font-weight: 500;
    line-height: 20px;
    margin-left: 0px;
    letter-spacing: 0.4em;
    text-decoration: none;
  }
  .blog-text08 {
    color: rgb(0, 0, 0);
  }
  .blog-text09 {
    color: rgb(0, 0, 0);
    font-size: 13px;
    font-family: Jost;
    font-weight: 500;
    text-decoration: none;
  }
  .blog-text10 {
    color: rgb(0, 0, 0);
    font-size: 13px;
    font-family: Jost;
    font-weight: 500;
    text-decoration: none;
  }
  .blog-text11 {
    color: rgb(0, 0, 0);
    font-size: 13px;
    font-family: Jost;
    font-weight: 500;
    text-decoration: none;
  }
  .blog-image01 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
  }
  .blog-blog-post-card {
    flex-direction: row;
    justify-content: space-between;
  }
  .blog-blog-post-card1 {
    flex-direction: row;
    justify-content: space-between;
  }
  .blog-blog-post-card2 {
    flex-direction: row;
    justify-content: space-between;
  }
  .blog-blog-post-card3 {
    flex-direction: row;
    justify-content: space-between;
  }
  .blog-blog-post-card4 {
    flex-direction: row;
    justify-content: space-between;
  }
  .blog-footer1 {
    flex-direction: column-reverse;
  }
  .blog-container23 {
    margin: 0px;
  }
  .blog-image13 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
  }
  .blog-links-container {
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media (max-width: 767px) {
  .blog-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .blog-mobile-menu {
    width: 767px;
  }
  .blog-blog {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .blog-blog-post-card {
    flex-direction: column;
  }
  .blog-image02 {
    width: 100%;
  }
  .blog-blog-post-card1 {
    flex-direction: column;
  }
  .blog-image04 {
    width: 100%;
  }
  .blog-blog-post-card2 {
    flex-direction: column;
  }
  .blog-image06 {
    width: 100%;
  }
  .blog-blog-post-card3 {
    flex-direction: column;
  }
  .blog-image08 {
    width: 100%;
  }
  .blog-blog-post-card4 {
    flex-direction: column;
  }
  .blog-image10 {
    width: 100%;
  }
  .blog-footer1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .blog-links-container {
    margin-top: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: space-between;
  }
}
@media (max-width: 479px) {
  .blog-container {
    padding-top: 55px;
  }
  .blog-header {
    padding: var(--dl-space-space-unit);
  }
  .blog-mobile-menu {
    width: 479px;
    padding: 16px;
  }
  .blog-blog {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .blog-container07 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .blog-text19 {
    width: auto;
  }
  .blog-container11 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .blog-text25 {
    width: auto;
  }
  .blog-container15 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .blog-text31 {
    width: auto;
  }
  .blog-container19 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .blog-text37 {
    width: auto;
  }
  .blog-container22 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .blog-text43 {
    width: auto;
  }
  .blog-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .blog-links-container {
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
}
