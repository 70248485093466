.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  padding-top: 70px;
  flex-direction: column;
}
.home-navbar {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 1000;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-pallet-green);
}
.home-header {
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.home-navbar1 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.home-middle {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 1;
  border-color: transparent;
  justify-content: center;
}
.home-left {
  display: flex;
  grid-gap: 45px;
  position: relative;
  align-items: flex-start;
}
.home-text {
  text-decoration: none;
}
.home-text01 {
  text-decoration: none;
}
.home-text02 {
  text-decoration: none;
}
.home-container01 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
}
.home-image {
  width: var(--dl-size-size-small);
  object-fit: cover;
}
.home-logo-center {
  align-self: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
}
.home-right {
  display: flex;
  grid-gap: 45px;
  position: relative;
  align-items: flex-start;
}
.home-text05 {
  text-decoration: none;
}
.home-text06 {
  text-decoration: none;
}
.home-text07 {
  text-decoration: none;
}
.home-burger-menu {
  display: none;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  display: none;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  padding: 32px;
  z-index: 100;
  position: absolute;
  transform: translateX(100%);
  transition: 0.3s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container02 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-logo-center1 {
  color: var(--dl-color-gray-black);
  height: auto;
  font-size: 24px;
  font-family: Jost;
  font-weight: 500;
  line-height: 20px;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
  letter-spacing: 0.4em;
  text-decoration: none;
}
.home-close-mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-middle1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
}
.home-text08 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.home-text09 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.home-text10 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.home-text11 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.home-text12 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.home-text13 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.home-container03 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.home-link {
  display: contents;
}
.home-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-link01 {
  display: contents;
}
.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  text-decoration: none;
}
.home-link02 {
  display: contents;
}
.home-image01 {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.home-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.home-hero {
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
  background-color: var(--dl-color-pallet-green);
}
.home-max-width {
  max-width: 1032px;
}
.home-hero1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container04 {
  display: flex;
  margin-right: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-info {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 1;
  border-color: transparent;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-image02 {
  width: 2px;
  height: 52px;
  margin: 0 14px 0 0;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.5);
}
.home-text14 {
  color: var(--dl-color-gray-black50);
  font-size: 12px;
  text-align: left;
  font-family: "Jost";
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
}
.home-text15 {
  max-width: 450px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-container05 {
  display: flex;
  align-items: flex-end;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
}
.home-text16 {
  color: var(--dl-color-gray-black80);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-right: 4px;
  letter-spacing: 0.1em;
}
.home-text17 {
  color: var(--dl-color-gray-black80);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}
.home-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-link03 {
  text-decoration: none;
}
.home-image03 {
  width: 100%;
  height: 669px;
  position: relative;
  max-width: 579px;
  box-sizing: border-box;
  max-height: 579px;
  object-fit: cover;
  border-color: transparent;
}
.home-book {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: #e5fe58;
}
.home-content {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-left1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 810px;
  align-items: flex-start;
  flex-direction: column;
}
.home-text18 {
  font-size: 54px;
  font-weight: initial;
  line-height: 65px;
}
.home-container06 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.home-container07 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-textinput {
  color: #000000;
  margin-top: var(--dl-space-space-halfunit);
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  margin-bottom: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: transparent;
}
.home-textinput1 {
  color: #000000;
  margin-top: var(--dl-space-space-halfunit);
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  margin-bottom: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: transparent;
}
.home-button {
  color: rgb(255, 255, 255);
  height: 52px;
  font-size: 24px;
  align-self: flex-end;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  margin-bottom: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  
  background-color: rgb(0, 0, 0);
}
.home-image04 {
  height: 400px;
  object-fit: cover;
}
.home-how-it-works {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #3c24a0;
}
.home-centered-container {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-heading {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text20 {
  color: var(--dl-color-gray-white);
}
.home-text22 {
  color: var(--dl-color-gray-white);
}
.home-category {
  color: white;
  width: 100%;
  display: flex;
  overflow: hidden;
  padding-left: var(--dl-space-space-fourunits);
  border-radius: 50px;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  background-color: #5228f5;
}
.home-category3{

  color: white;
  width: 100%;
  display: flex;
  overflow: hidden;
  padding-left: var(--dl-space-space-fourunits);
  border-radius: 50px;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  background-color: #5228f5;
  margin-top: 40px;
}
.home-headng {
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.home-text24 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
}
.home-text27 {
  color: rgb(238, 233, 254);
  line-height: 24px;
  margin-bottom: 40px;
}
.home-navlink {
  display: contents;
}
.home-get-started {
  margin-bottom: 0px;
  text-decoration: none;
}
.home-get-started:hover {
  color: black;
  background-color: #ffffff;
}
.home-text28 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-container08 {
  flex: 1;
  height: 100%;
  display: flex;
  align-self: flex-end;
  flex-direction: row;
}
.home-pasted-image {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-row {
  display: flex;
  grid-gap: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: row;
}
.home-category1 {
  flex: 1;
  color: white;
  height: 555px;
  display: flex;
  overflow: hidden;
  align-items: flex-end;
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
}
.home-headng1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: 0px;
}
.home-text29 {
  color: rgb(0, 0, 0);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
}
.home-text30 {
  color: rgb(30, 30, 30);
  line-height: 24px;
}
.home-pasted-image1 {
  width: 100%;
}
.home-category2 {
  flex: 1;
  color: white;
  height: 555px;
  display: flex;
  overflow: hidden;
  align-items: flex-end;
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000000;
}
.home-headng2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: 0px;
}
.home-text31 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
}
.home-text32 {
  color: rgb(238, 233, 254);
  line-height: 24px;
}
.home-pasted-image2 {
  width: 100%;
  height: 598px;
  object-fit: contain;
}
.home-pricing {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #5228f5;
}
.home-centered-container1 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-heading1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text33 {
  color: var(--dl-color-gray-white);
  text-align: center;
}
.home-text34 {
  color: rgb(238, 233, 254);
  width: 600px;
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-cards {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
}
.home-card {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  background-color: #ffffff;
}
.home-text35 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text36 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-navlink1 {
  display: contents;
}
.home-get-started1 {
  transition: 0.3s;
  text-decoration: none;
}
.home-get-started1:hover {
  color: var(--dl-color-gray-white);
}
.home-text39 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 600;
}
.home-text39:hover {
  color: #000000;
}
.home-text40 {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-bullet-points {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-point {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon08 {
  fill: #5228f5;
  width: 8px;
  height: 8px;
}
.home-text41 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.home-point1 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon10 {
  fill: #5228f5;
  width: 8px;
  height: 8px;
}
.home-text42 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.home-point2 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon12 {
  fill: #5228f5;
  width: 8px;
  height: 8px;
}
.home-text43 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.home-point3 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon14 {
  fill: #5228f5;
  width: 8px;
  height: 8px;
}
.home-text44 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.home-point4 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon16 {
  fill: #5228f5;
  width: 8px;
  height: 8px;
}
.home-text45 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.home-card1 {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: 40px;
  border-radius: 50px;
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  background-color: #ffffff;
}
.home-text46 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text47 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-navlink2 {
  display: contents;
}
.home-get-started2 {
  transition: 0.3s;
  text-decoration: none;
}
.home-get-started2:hover {
  color: var(--dl-color-gray-white);
}
.home-text48 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 600;
}
.home-text48:hover {
  color: #000000;
}
.home-text51 {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-bullet-points1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-point5 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon18 {
  fill: #5228f5;
  width: 8px;
  height: 8px;
}
.home-text52 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.home-point6 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon20 {
  fill: #5228f5;
  width: 8px;
  height: 8px;
}
.home-text53 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.home-point7 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon22 {
  fill: #5228f5;
  width: 8px;
  height: 8px;
}
.home-text54 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.home-point8 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon24 {
  fill: #5228f5;
  width: 8px;
  height: 8px;
}
.home-text55 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.home-card2 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: 40px;
  border-radius: 50px;
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  background-color: #000000;
}
.home-text56 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text57 {
  color: rgb(255, 255, 255);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-navlink3 {
  display: contents;
}
.home-get-started3 {
  color: var(--dl-color-gray-black);
  transition: 0.3s;
  text-decoration: none;
  background-color: #ffffff;
}
.home-get-started3:hover {
  color: var(--dl-color-gray-white);
  background-color: #000000;
}
.home-text60 {
  font-size: 18px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 600;
}
.home-text60:hover {
  color: #ffffff;
}
.home-pricing1 {
  width: 100%;
  display: flex;
  justify-content: center;
}
.home-container09 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: 136px;
  justify-content: center;
  background-color: #5228f5;
}
.home-container10 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
}
.home-text63 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 700;
}
.home-container11 {
  flex: 2;
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.home-container12 {
  flex: 1;
  height: 477px;
  display: flex;
  max-width: 450px;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-gray-white);
}
.home-container12:hover {
  transform: scale(1.02);
}
.home-text64 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
}
.home-text68 {
  margin: var(--dl-space-space-twounits);
  font-size: 4rem;
  font-weight: 200;
}
.home-text69 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-navlink4 {
  color: var(--dl-color-gray-white);
  margin-top: auto;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-gray-black);
}
.home-container13 {
  flex: 1;
  color: var(--dl-color-gray-white);
  height: 486px;
  display: flex;
  max-width: 450px;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-gray-black);
}
.home-container13:hover {
  transform: scale(1.02);
}
.home-text70 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
}
.home-text75 {
  margin: var(--dl-space-space-twounits);
  font-size: 4rem;
  font-weight: 200;
}
.home-text76 {
  font-weight: bold;
  margin-bottom: var(--dl-space-space-unit);
}
.home-navlink5 {
  margin-top: auto;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: #ffffff;
}
.home-refer {
  gap: 120px;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: 140px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.home-header1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text77 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  max-width: 1000px;
  font-style: normal;
  font-weight: 400;
}
.home-hero-image {
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: 180px;
  padding-right: 180px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: #e5fe58;
}
.home-image05 {
  width: 640px;
  height: 540px;
  object-fit: cover;
}
.home-who {
  gap: 120px;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 320px;
}
.home-header2 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 170px;
  padding-right: 170px;
  flex-direction: column;
}
.home-heading2 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-text78 {
  width: 100%;
  font-size: 54px;
  max-width: 800px;
  font-style: normal;
  font-weight: 400;
}
.home-text79 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 24px;
  max-width: 800px;
  line-height: 43px;
}
.home-link04 {
  display: flex;
  font-size: 24px;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
  text-decoration: none;
  background-color: rgb(229, 254, 88);
}
.home-icon26 {
  width: 24px;
  height: 24px;
  margin-left: var(--dl-space-space-halfunit);
}
.home-preview {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-left: 170px;
  padding-right: 170px;
  flex-direction: row;
}
.home-image06 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 527px;
  align-items: flex-start;
  flex-direction: column;
  background-color: #e5fe58;
}
.home-image07 {
  object-fit: cover;
  margin-left: -160px;
}
.home-video {
  top: 0px;
  right: 0px;
  width: 646px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
.home-video1 {
  width: 100%;
  cursor: pointer;
  margin-top: 77px;
}
.home-footer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-950);
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  background-color: #ffffff;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-max-width1 {
  background-color: #ffffff;
}
.home-footer1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: space-between;
}
.home-container14 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  margin-left: var(--dl-space-space-sevenunits);
  flex-direction: column;
}
.home-image08 {
  width: var(--dl-size-size-medium);
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text81 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-container15 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.home-link05 {
  display: contents;
}
.home-icon28 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-link06 {
  display: contents;
}
.home-icon30 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-container16 {
  flex: 0 0 auto;
  width: var(--dl-size-size-xsmall);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link07 {
  display: contents;
}
.home-image09 {
  width: var(--dl-size-size-xsmall);
  object-fit: cover;
  text-decoration: none;
}
.home-text82 {
  color: var(--dl-color-gray-black50);
  margin-top: var(--dl-space-space-oneandhalfunits);
}
.home-text83 {
  color: var(--dl-color-gray-black50);
  margin-top: var(--dl-space-space-twounits);
}
.home-text84 {
  color: var(--dl-color-gray-black50);
  margin-top: 12px;
}
.home-text85 {
  color: var(--dl-color-gray-black50);
  margin-top: 12px;
}
.home-links-container {
  display: flex;
  grid-gap: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-container17 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-text88 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.home-navlink6 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link08 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-navlink7 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link09 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
@media (max-width: 991px) {
  .home-left {
    display: none;
  }
  .home-right {
    display: none;
  }
  .home-burger-menu {
    display: flex;
  }
  .home-icon {
    width: 24px;
    height: 24px;
    display: flex;
  }
  .home-mobile-menu {
    width: 991px;
  }
  .home-logo-center1 {
    color: var(--dl-color-gray-black);
    font-size: 24px;
    font-family: Jost;
    font-weight: 500;
    line-height: 20px;
    margin-left: 0px;
    letter-spacing: 0.4em;
    text-decoration: none;
  }
  .home-text08 {
    color: rgb(0, 0, 0);
  }
  .home-text09 {
    color: rgb(0, 0, 0);
    font-size: 13px;
    font-family: Jost;
    font-weight: 500;
    text-decoration: none;
  }
  .home-text10 {
    color: rgb(0, 0, 0);
    font-size: 13px;
    font-family: Jost;
    font-weight: 500;
    text-decoration: none;
  }
  .home-text11 {
    color: rgb(0, 0, 0);
    font-size: 13px;
    font-family: Jost;
    font-weight: 500;
    text-decoration: none;
  }
  .home-image01 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
  }
  .home-hero1 {
    flex-direction: column;
  }
  .home-container04 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .home-text15 {
    text-align: center;
  }
  .home-book {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-fourunits);
    padding-left: 5px;
  }
  .home-content {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column-reverse;
    justify-content: center;
  }
  .home-left1 {
    gap: var(--dl-space-space-oneandhalfunits);
    align-items: center;
  }
  .home-text18 {
    font-size: 32px;
    text-align: center;
    line-height: 38px;
  }
  .home-text19 {
    font-size: 16px;
  }
  .home-image04 {
    height: 220px;
  }
  .home-category {
    padding-left: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-category3{

    padding-left: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-top: 20px;
  }
  .home-headng {
    margin-left: var(--dl-space-space-fourunits);
  }
  .home-container08 {
    width: 100%;
  }
  .home-pasted-image {
    height: auto;
  }
  .home-headng1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .home-headng2 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .home-text34 {
    width: 100%;
    max-width: 600px;
  }
  .home-cards {
    display: flex;
    flex-direction: column;
  }
  .home-card {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-text36 {
    font-size: 16px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text40 {
    font-size: 16px;
  }
  .home-card1 {
    width: 100%;
    align-items: flex-start;
  }
  .home-card2 {
    width: 100%;
    align-items: flex-start;
  }
  .home-container09 {
    align-items: center;
    flex-direction: column;
  }
  .home-container10 {
    align-items: center;
  }
  .home-container11 {
    width: 100%;
  }
  .home-refer {
    gap: var(--dl-space-space-fourunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-text77 {
    font-size: 32px;
  }
  .home-hero-image {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-who {
    gap: var(--dl-space-space-fourunits);
    margin-bottom: 750px;
    padding-bottom: 0px;
  }
  .home-header2 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading2 {
    gap: var(--dl-space-space-unit);
  }
  .home-text78 {
    font-size: 32px;
  }
  .home-text79 {
    font-size: 16px;
    line-height: 28px;
  }
  .home-link04 {
    font-size: 16px;
  }
  .home-preview {
    align-items: flex-end;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-image06 {
    width: 80%;
    height: 100%;
  }
  .home-image07 {
    width: 100%;
  }
  .home-video {
    top: auto;
    left: 24px;
    right: auto;
    bottom: -729px;
  }
  .home-footer1 {
    flex-direction: column-reverse;
  }
  .home-container14 {
    margin: 0px;
  }
  .home-image09 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
  }
  .home-links-container {
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media (max-width: 767px) {
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-mobile-menu {
    width: 767px;
  }
  .home-main {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-hero {
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-how-it-works {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading {
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-text20 {
    margin-bottom: 0px;
  }
  .home-headng {
    padding-bottom: 0px;
  }
  .home-get-started {
    display: none;
  }
  .home-container08 {
    width: 100%;
  }
  .home-row {
    height: auto;
    flex-direction: column;
  }
  .home-pricing {
    width: 100%;
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .home-card {
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text35 {
    font-size: 28px;
  }
  .home-card1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-text46 {
    font-size: 28px;
  }
  .home-card2 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-text56 {
    font-size: 28px;
  }
  .home-container09 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-container11 {
    flex-direction: column;
  }
  .home-container12 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-container13 {
    width: 100%;
  }
  .home-image05 {
    width: auto;
    height: 220px;
  }
  .home-who {
    margin-bottom: 0px;
  }
  .home-video {
    width: 85%;
    bottom: -500px;
    z-index: 100;
    position: static;
    align-self: center;
    margin-top: -48px;
  }
  .home-video1 {
    margin-top: 0px;
  }
  .home-footer1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-links-container {
    margin-top: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: space-between;
  }
}
@media (max-width: 479px) {
  .home-container {
    padding-top: 55px;
  }
  .home-header {
    padding: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-twounits);
  }
  
  .home-mobile-menu {
    width: 479px;
    padding: 16px;
  }
  .home-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container04 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-btn-group {
    flex-direction: column;
    align-items: flex-start;
  }
  .home-text15 {
    max-width: 350px;
    font-size: 30px;
  }

  
  .home-category {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-category3{

    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-top: 20px;
  }
  .home-pricing {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-get-started3 {
    background-color: var(--dl-color-gray-white);
  }
  .home-container09 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-image07 {
    margin-left: -120px;
  }
  .home-video {
    bottom: -419px;
  }
  .home-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .home-links-container {
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
}
