.blog2-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  padding-top: 70px;
  flex-direction: column;
  background-color: #ffffff;
}
.blog2-navbar {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 1000;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}
.blog2-header {
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.blog2-navbar1 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.blog2-middle {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 1;
  border-color: transparent;
  justify-content: center;
}
.blog2-left {
  display: flex;
  grid-gap: 45px;
  position: relative;
  align-items: flex-start;
}
.blog2-text {
  text-decoration: none;
}
.blog2-text01 {
  text-decoration: none;
}
.blog2-text02 {
  text-decoration: none;
}
.blog2-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
}
.blog2-image {
  width: var(--dl-size-size-small);
  object-fit: cover;
}
.blog2-logo-center {
  align-self: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.blog2-right {
  display: flex;
  grid-gap: 45px;
  position: relative;
  align-items: flex-start;
}
.blog2-text05 {
  text-decoration: none;
}
.blog2-text06 {
  text-decoration: none;
}
.blog2-text07 {
  text-decoration: none;
}
.blog2-burger-menu {
  display: none;
}
.blog2-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  display: none;
}
.blog2-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  padding: 32px;
  z-index: 100;
  position: absolute;
  transition: transform 0.3s ease-in-out; /* Add transition property */
  transform: translateY(-100%);
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.blog2-mobile-menu.open {
  transform: translateY(0); /* Slide the menu into view */
}
.blog2-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.blog2-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.blog2-logo-center1 {
  color: var(--dl-color-gray-black);
  height: auto;
  font-size: 24px;
  font-family: Jost;
  font-weight: 500;
  line-height: 20px;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
  letter-spacing: 0.4em;
  text-decoration: none;
}
.blog2-close-mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.blog2-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.blog2-middle1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
}
.blog2-text08 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.blog2-text09 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.blog2-text10 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.blog2-text11 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.blog2-text12 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.blog2-text13 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.blog2-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.blog2-link {
  display: contents;
}
.blog2-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.blog2-link1 {
  display: contents;
}
.blog2-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  text-decoration: none;
}

.blog2-icon04,
.blog2-icon06 {
  fill: #000 !important;
  color: #000 !important; /* Change to your desired color */
}

.blog2-link2 {
  display: contents;
}
.blog2-image1 {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.blog2-hero {
  width: 100%;
  height: 679px;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  min-height: 80vh;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-color: #d9d9d9;
  background-image: url("https://images.unsplash.com/photo-1573152143286-0c422b4d2175?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDV8fFNPQ0lBTCUyME1FRElBfGVufDB8fHx8MTcwNjYyNDQ3OHww&ixlib=rb-4.0.3&w=1500");
  background-position: bottom;
}
.blog2-container4 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.blog2-text14 {
  color: rgb(255, 255, 255);
  width: 537px;
  font-size: 3rem;
  max-width: 25rem;
}
.blog2-text15 {
  font-size: 1.15rem;
  max-width: 60%;
  margin-top: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-threeunits);
}
.blog2-banner {
  width: 100%;
  height: 1413px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.blog2-text22 {
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-twounits);
  text-align: left;
  margin-left: var(--dl-space-space-sixunits);
  margin-right: var(--dl-space-space-sixunits);
  margin-bottom: var(--dl-space-space-twounits);
}
.blog2-footer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-950);
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  background-color: #ffffff;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.blog2-max-width {
  width: 100%;
  background-color: #ffffff;
}
.blog2-footer1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: space-between;
}
.blog2-container5 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  margin-left: var(--dl-space-space-sevenunits);
  flex-direction: column;
}
.blog2-image2 {
  width: var(--dl-size-size-medium);
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unit);
}
.blog2-text83 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.blog2-container6 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.blog2-link3 {
  display: contents;
}
.blog2-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.blog2-link4 {
  display: contents;
}
.blog2-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.blog2-container7 {
  flex: 0 0 auto;
  width: var(--dl-size-size-xsmall);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.blog2-link5 {
  display: contents;
}
.blog2-image3 {
  width: var(--dl-size-size-xsmall);
  object-fit: cover;
  text-decoration: none;
}
.blog2-text84 {
  color: var(--dl-color-gray-black50);
  margin-top: var(--dl-space-space-oneandhalfunits);
}
.blog2-text85 {
  color: var(--dl-color-gray-black50);
  margin-top: var(--dl-space-space-twounits);
}
.blog2-text86 {
  color: var(--dl-color-gray-black50);
  margin-top: 12px;
}
.blog2-text87 {
  color: var(--dl-color-gray-black50);
  margin-top: 12px;
}
.blog2-links-container {
  display: flex;
  grid-gap: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.blog2-container8 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  justify-content: flex-start;
}
.blog2-text90 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.blog2-navlink {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.blog2-navlink1 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.blog2-navlink2 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.blog2-navlink3 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
@media (max-width: 991px) {
  .blog2-left {
    display: none;
  }
  .blog2-right {
    display: none;
  }
  .blog2-burger-menu {
    display: flex;
  }
  .blog2-icon {
    width: 24px;
    height: 24px;
    display: flex;
  }
  .blog2-mobile-menu {
    width: 967px;
  }
  .blog2-logo-center1 {
    color: var(--dl-color-gray-black);
    font-size: 24px;
    font-family: Jost;
    font-weight: 500;
    line-height: 20px;
    margin-left: 0px;
    letter-spacing: 0.4em;
    text-decoration: none;
  }
  .blog2-text08 {
    color: rgb(0, 0, 0);
  }
  .blog2-text09 {
    color: rgb(0, 0, 0);
    font-size: 13px;
    font-family: Jost;
    font-weight: 500;
    text-decoration: none;
  }
  .blog2-text10 {
    color: rgb(0, 0, 0);
    font-size: 13px;
    font-family: Jost;
    font-weight: 500;
    text-decoration: none;
  }
  .blog2-text11 {
    color: rgb(0, 0, 0);
    font-size: 13px;
    font-family: Jost;
    font-weight: 500;
    text-decoration: none;
  }
  .blog2-image1 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
  }
  .blog2-banner {
    height: 1653px;
  }
  .blog2-text22 {
    max-width: 100%;
  }
  .blog2-footer1 {
    flex-direction: column-reverse;
  }
  .blog2-container5 {
    margin: 0px;
  }
  .blog2-image3 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
  }
  .blog2-links-container {
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media (max-width: 767px) {
  .blog2-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .blog2-mobile-menu {
    width: 767px;
  }
  .blog2-hero {
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .blog2-text14 {
    text-align: center;
  }
  .blog2-text15 {
    max-width: 100%;
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .blog2-banner {
    height: 1902px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .blog2-text22 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .blog2-footer1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .blog2-links-container {
    margin-top: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: space-between;
  }
}
@media (max-width: 479px) {
  .blog2-container {
    padding-top: 55px;
  }
  .blog2-header {
    padding: var(--dl-space-space-unit);
  }
  .blog2-mobile-menu {
    width: 479px;
    padding: 16px;
  }
  .blog2-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .blog2-text15 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .blog2-banner {
    height: 3217px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .blog2-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .blog2-links-container {
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
}
